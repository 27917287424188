export const hideLogo = (options, setOptions) => {
  if (!options.image) return

  localStorage.setItem(
    'temporaly_link',
    JSON.stringify({ image: options.image })
  )

  setOptions({
    ...options,
    image: ''
  })
}

export const showLogo = (options, setOptions) => {
  const linkFromLocalStorage =
		localStorage.getItem('temporaly_link') || "{image:''}"

  if (linkFromLocalStorage) {
    const link = JSON.parse(linkFromLocalStorage)
    setOptions({
      ...options,
      image: link.image
    })

    localStorage.removeItem('temporaly_link_to_img')
  }
}
