import React, { useState } from 'react'

import Input from '../../reusable/Input'
import { Col, Row } from '../../../../styles/styles'
import DescriptionForTabs from '../../reusable/Tabs/DescriptionForTabs'

let isInit = true

const dataDescription = {
  subtitle: 'Quickly share any webpage:',
  description:
        "When someone scans the QR code from their phone, it'll open their browser to the page / URL you specify"
}

const UrlType = ({ options, setOptions }) => {
  const [past, setPast] = useState(null)
  return (
    <Col>
      <DescriptionForTabs data={dataDescription} />

      <label htmlFor='url'>URL:</label>
      <Row ai='flex-end' g='5px'>
        <Input
          id='url'
          name='url'
          placeholder='Your URL address'
          value={options.data}
          onChange={(e) => {
            const valid = /^(ftp|http|https):\/\/[^ "]+$/.test(
              e.target.value
            )
            if (valid) {
              setOptions({
                ...options,
                data: past || e.target.value
              })
              setPast(null)
            } else {
              setOptions({ ...options, data: 'https://' })
            }
          }}
          w='100%'
          autoFocus // on first render we automatically focused on this input
                    // if u want to change it logic, please change initial event for Google Tag Manager
          onPaste={(e) => {
            const pastedText = e.clipboardData.getData(
              'text/plain'
            )
            const valid = /^(ftp|http|https):\/\/[^ "]+$/.test(
              pastedText
            )

            if (valid) {
              setPast(pastedText)

              setOptions({
                ...options,
                data: pastedText
              })
            } else {
              setPast(`https://${pastedText}`)
              setOptions({
                ...options,
                data: `https://${pastedText}`
              })
            }
          }}
          onBlur={() => {
            if (isInit) {
              // 👇🏻 event for Google Tag Manager here 👇🏻
              window.dataLayer.push({
                event: 'form_start',
                form_name: 'qr-code-builder'
              })
              // 👆🏻 event for Google Tag Manager here 👆🏻

              isInit = false
            }
          }}
        />
      </Row>
    </Col>
  )
}

export default UrlType
