import React, { useState } from 'react'
import { FormikCheckbox } from '@pga/pga-component-library'
import { Col } from '../../../../styles/styles'
import { hideLogo, showLogo } from '../../../../utils/switchLogo'
import UploadImage from './UploadImage'
import ExampleImages from './ExampleImages'
import styled from 'styled-components'

const FormikCheckboxWrapper = styled.div`
  span {
    border: 1px solid #9ba1a3 !important;
  }
`

const EditQrImage = ({ options, setOptions }) => {
  const [withoutImage, setWithoutImage] = useState(false)
  const [showWithoutImage, setShowWithoutImage] = useState(false)

  return (
    <Col g='20px'>
      <ExampleImages
        options={options}
        setOptions={setOptions}
        setWithoutImage={setWithoutImage}
        withoutImage={withoutImage}
        setShowWithoutImage={setShowWithoutImage}
      />
      <UploadImage
        setShowWithoutImage={setShowWithoutImage}
        disabled={withoutImage}
        options={options}
        setOptions={setOptions}
      />
      {showWithoutImage && (
        <FormikCheckboxWrapper>
          <FormikCheckbox
            label='No logo'
            id='withoutImage'
            field={{
              name: 'withoutImage',
              onChange: function noRefCheck (e) {
                setWithoutImage(e.target.checked)

                if (e.target.checked) {
                  hideLogo(options, setOptions)
                } else {
                  showLogo(options, setOptions)
                }
              },
              value: withoutImage
            }}
          />
        </FormikCheckboxWrapper>
      )}
    </Col>
  )
}

export default EditQrImage
