import React from 'react'
import { Col, SubtitleStyled, TitleStyled } from '../../../../styles/styles'

const DescriptionForTabs = ({ data }) => {
  return (
    <Col m='5px 0'>
      <TitleStyled m='0 0 5px 0'>{data.subtitle}</TitleStyled>
      <SubtitleStyled>{data.description}</SubtitleStyled>
    </Col>
  )
}

export default DescriptionForTabs
