import React from 'react'
import styled from 'styled-components'

const WrappSelect = styled.select`
height: calc(1.5em + 0.75rem + 2px);
padding: 0.375rem 0.75rem;

font-family: "Montserrat",sans-serif;
font-size: 15px;
font-weight: 400;

line-height: 1.5;
color: #495057;
background-color: #ffffff;
background-clip: padding-box;
border: 1px solid #ced4da;
outline: none;
`

const Select = ({ children, name, id, onChange, value }) => {
  return (
    <WrappSelect name={name} id={id} onChange={onChange} value={value}>
      {children}
    </WrappSelect>
  )
}

export default Select
