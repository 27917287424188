import React, { useEffect, useMemo, useState } from 'react'
import Qr from '../../components/QrComponents'
import Layout from '../../components/layout'
import { hardcodeImage } from '../../components/QrComponents/reusable/mockData'
import { Container } from '@pga/pga-component-library'

const initQrOptions = {
  width: 340,
  height: 340,
  type: 'canvas',
  data: 'https://',
  image: hardcodeImage,
  margin: 15,
  qrOptions: {
    typeNumber: 0,
    mode: 'Byte',
    errorCorrectionLevel: 'Q'
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: .5,
    margin: 0,
    crossOrigin: 'anonymous'
  },
  dotsOptions: {
    color: '#000000',
    type: 'rounded'
  },
  backgroundOptions: {
    color: '#ffffff'
  },
  cornersSquareOptions: {
    color: '#000000',
    type: 'extra-rounded'
  },
  cornersDotOptions: {
    color: '#000000',
    type: 'dot'
  },
  textTips: ''
}

const QrCode = () => {
  const [qrCode, setQrCode] = useState(null)
  const [options, setOptions] = useState(initQrOptions)

  const qrCodeMemo = useMemo(() => qrCode, [qrCode])
  const optionsMemo = useMemo(() => options, [options])

  useEffect(() => {
    if (typeof window !== 'undefined' && !qrCodeMemo) {
      const QRCodeStyling = require('qr-code-styling') // Only do this on the client
      setQrCode(new QRCodeStyling(optionsMemo))
    }
  }, [qrCodeMemo, optionsMemo])

  return (
    <Layout>
      <Container>
      <h2>Create a QR Code</h2>
        <Qr qrCode={qrCodeMemo} options={optionsMemo} setOptions={setOptions} />
      </Container>
    </Layout>
  )
}

export default QrCode

export const Head = () => <title>QR</title>
