import React, { useState } from 'react'

import UploadWidget from '../../../../utils/UploadWidget'
import { Col } from '../../../../styles/styles'
import Button from '../../reusable/Button'

const UploadImage = ({
  disabled,
  options,
  setOptions,
  setShowWithoutImage
}) => {
  const [error, updateError] = useState()

  const handleOnUpload = (error, result, widget) => {
    if (error) {
      updateError(error)
      widget.close({
        quiet: true
      })
      return
    }
    setShowWithoutImage(true)

    setOptions({
      ...options,
      image: result.info.secure_url
    })
  }

  return (
    <Col>
      <UploadWidget onUpload={handleOnUpload}>
        {({ open }) => {
          const handleOnClick = (e) => {
            e.preventDefault()
            open()
          }

          return (
            <Button
              w='100%'
              title='Upload a custom image'
              secondary
              disabled={disabled}
              onClick={handleOnClick}
            />
          )
        }}
      </UploadWidget>

      {error && <p>{error}</p>}
    </Col>
  )
}

export default UploadImage
