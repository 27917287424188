import React, { useState } from 'react'
import styled from 'styled-components'

import { Col, Row } from '../../../styles/styles'
import arrDown from '../../../images/qr/arrow-down-white.png'
import { Title } from '@pga/pga-component-library/lib/components/NotFound'

const TitleStyled = styled(Title)`
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  text-transform: uppercase;

  color: white !important;
  margin: 0 !important;
`

const AccordionItem = styled(Col)`
  border: none;
  color: white;
  background: #ddebff !important;

  cursor: pointer;
`

const AccordionWrappTitle = styled(Row)`
  align-items: center;
  justify-content: space-between;

  background: #00234b;
  padding: 15px 20px;
  width: 100%;

  img {
    stroke: white;
    fill: white;
    width: 20px;
  }
`

const WrappArrow = styled.div`
  transform: ${({ isRotate }) =>
    isRotate ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: 0.5s;
`

const AccordionContent = styled.div`
  padding: 15px 20px;
  color: black;
`

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <AccordionItem>
      <AccordionWrappTitle onClick={() => setIsActive(!isActive)}>
        <TitleStyled>{title}</TitleStyled>
        <WrappArrow isRotate={isActive}>
          <img src={arrDown} alt='White arr Down' />
        </WrappArrow>
      </AccordionWrappTitle>
      {isActive && <AccordionContent>{content}</AccordionContent>}
    </AccordionItem>
  )
}

export default Accordion
