import React, { useRef, useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { Col, Row } from '../../../styles/styles'
import Button from '../reusable/Button'

import { Copy } from './Copy'
import {
  onDownloadClick,
  transformQr,
  updateLinkToQr
} from '../../../utils/qrUtils'

import { QrPlaceholder } from '../reusable/QrPlaceholder'
import useDebounce from '../../../hooks/useDebounce'

const QrStyled = styled(Row)`
  display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  margin: 0px auto;

  ${({ size }) => `width: ${size}px`}
  ${({ size }) => `height: ${size}px`}
  
  svg,
  canvas {
    border-radius: 20px;
  }

  @media (max-width: 1280px) {
    display: none;
  }
`

const MobilQr = styled.img`
  display: none;

  @media (max-width: 1280px) {
    display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
  }
`

const regexQrValues = /^(https:\/\/|smsto:|WIFI:T:WPA;S:;P:;H:false;;| )$/gm

const QrMain = ({ qrCode, isTypes, options, textTips, size }) => {
  const ref = useRef(null)
  const refQrStyledCopy = useRef(document.createElement('div'))

  const [qrCodeCopy, setQrCodeCopy] = useState(null)
  const [linkFromBlob, setLinkFromBlob] = useState(null)
  const [update, setUpdate] = useState(0)
  const [isIphone, setIsIphone] = useState(false)

  const qrCodeMemo = useMemo(() => qrCodeCopy, [qrCodeCopy])
  const triggerUpdate = useDebounce(update, 1100)

  useEffect(() => {
    if (qrCode && ref.current) {
      setIsIphone(/iPhone|iPad|iPod/i.test(navigator.userAgent))
      qrCode.append(ref.current)
    }
  }, [qrCode, ref])

  useEffect(() => { // for copy Qr with size 1024
    if (typeof window !== 'undefined' && !qrCodeMemo) {
      const QRCodeStyling = require('qr-code-styling') // Only do this on the client
      setQrCodeCopy(new QRCodeStyling(options))
    }
    if (qrCodeCopy && refQrStyledCopy.current) { transformQr(qrCodeCopy, refQrStyledCopy, options, textTips) }
    setUpdate(update + 1)
  }, [qrCodeCopy, refQrStyledCopy, textTips, options])

  useEffect(() => { // for mobile updating
    updateLinkToQr(ref, linkFromBlob, setLinkFromBlob, size)
  }, [triggerUpdate])

  return (
    <Col flex='1 1 50%'>
      <Col pos='sticky' posT='20px' g='15px'>
        <QrPlaceholder
          size={options.width}
          isShow={options.data.match(regexQrValues)}
        />

        <QrStyled
          ref={ref}
          isShow={!options.data.match(regexQrValues)}
        />

        {linkFromBlob && (
          <MobilQr
            src={linkFromBlob}
            alt='Qr-Code'
            isShow={!options.data.match(regexQrValues)}
          />
        )}

        <Col g='15px'>
          <Button
            onClick={() => onDownloadClick(textTips, isTypes, options)}
            title='Download'
            w={size.width < 1280 ? '100%' : '50%'}
            m='0 auto'
            disabled={options.data.match(regexQrValues)}
          />

          {!isIphone && (
            <Copy
              size={size}
              refQrStyledCopy={refQrStyledCopy}
              options={options}
              isTypes={isTypes}
              disabled={options.data.match(regexQrValues)}
            />
          )}
        </Col>
      </Col>
    </Col>
  )
}

export default QrMain
