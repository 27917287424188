import React, { useEffect, useState } from 'react'
import Input from '../reusable/Input'
import useDebounce from '../../../hooks/useDebounce'
import { extension } from '../../../utils/qrUtils'

const Tips = ({ qrCode, textTips, setTextTips, setOptions, options }) => {
  const [isLoading, setIsLoading] = useState(false)
  const triggerTextTips = useDebounce(textTips, 1800, setIsLoading)

  useEffect(() => {
    setOptions({ ...options, textTips: triggerTextTips })

    if (!triggerTextTips) return

    qrCode.applyExtension(extension)
  }, [triggerTextTips, qrCode])

  return (
    <Input
      title={<label htmlFor='caption'>Caption below QR code:</label>}
      name='caption'
      id='caption'
      onChange={(e) => setTextTips(e.target.value)}
      value={textTips}
      isLoading={isLoading}
    />
  )
}

export default Tips
