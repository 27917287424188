import React, { useEffect, useState } from 'react'
import useDebounce from '../../../../hooks/useDebounce'
import { FormikCheckbox } from '@pga/pga-component-library'

import { Col } from '../../../../styles/styles'

import RedStar from '../../reusable/RedStar'
import Input from '../../reusable/Input'
import Select from '../../reusable/Select'
import styled from 'styled-components'
import DescriptionForTabs from '../../reusable/Tabs/DescriptionForTabs'

const FormikCheckboxWrapper = styled.div`
    * {
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
    }

    span {
        border: 1px solid #9ba1a3 !important;
    }
`

const dataDescription = {
  subtitle: 'A quick way to share your Wi-Fi network:',
  description:
        "When someone scans the QR code from their phone, it'll connect their phone to Wi-Fi"
}

const WiFiType = ({ options, setOptions, size }) => {
  const [wiFi, setWiFi] = useState({
    networkName: '',
    pass: '',
    networkType: 'WPA',
    hide: false
  })

  const debounceValue = useDebounce(wiFi, 1100)
  const triggerWiFi = size.width < 1024 ? debounceValue : wiFi

  useEffect(() => {
    let formatedWiFiCode
    if (wiFi.networkType) {
      formatedWiFiCode = `WIFI:T:${wiFi.networkType};S:${wiFi.networkName};P:${wiFi.pass};H:${wiFi.hide};;`
    } else {
      formatedWiFiCode = `WIFI:S:${wiFi.networkName};H:${wiFi.hide};;`
    }

    setOptions({
      ...options,
      data: formatedWiFiCode
    })
  }, [triggerWiFi])

  return (
    <Col g='20px'>
      <DescriptionForTabs data={dataDescription} />

      <Col ai='stretch'>
        <label htmlFor='networkName'>
          Network name:
          <RedStar />
        </label>
        <Input
          id='networkName'
          type='text'
          name='networkName'
          value={wiFi.networkName}
          onChange={(e) =>
            setWiFi({
              ...wiFi,
              networkName: e.target.value
            })}
          autoFocus
        />
      </Col>

      <Col ai='stretch'>
        <label htmlFor='networkType'>
          Network type:
          <RedStar />
        </label>
        <Select
          name='networkType'
          id='networkType'
          onChange={(e) =>
            setWiFi({
              ...wiFi,
              networkType: e.target.value
            })}
          value={wiFi.networkType}
        >
          <option value='WEP'>WEP</option>
          <option value='WPA'>WPA/WPA2</option>
          <option value=''>No encryption</option>
        </Select>
      </Col>

      {wiFi.networkType && (
        <Col ai='stretch'>
          <label htmlFor='pass'>
            Password:
            <RedStar />
          </label>
          <Input
            id='pass'
            type='text'
            name='pass'
            value={wiFi.pass}
            onChange={(e) =>
              setWiFi({
                ...wiFi,
                pass: e.target.value
              })}
          />
        </Col>
      )}

      <Col ai='flex-start'>
        <FormikCheckboxWrapper>
          <FormikCheckbox
            label='Hidden'
            id='hide'
            field={{
              name: 'hide',
              onChange: function noRefCheck (e) {
                setWiFi({
                  ...wiFi,
                  hide: e.target.checked
                })
              },
              value: wiFi.hide
            }}
          />
        </FormikCheckboxWrapper>
      </Col>
    </Col>
  )
}

export default WiFiType
