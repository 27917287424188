import React from 'react'
import styled, { keyframes } from 'styled-components'

import { Col } from '../../../styles/styles'

const WrappInput = styled(Col)`
  position: relative;
  cursor: pointer;


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
`

const rotating = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`

const Loader = styled.div`
position: absolute;
right: 10px;
top: 38px;
width: 20px;
height: 20px;
border: 3px solid rgba(6, 35, 75, 0.2);
border-radius: 50%;
border-top-color: rgb(6, 35, 75);
background: transparent;
animation: ${rotating} 1s linear infinite;
`

const InputStyled = styled.input`
height: calc(1.5em + 0.75rem + 2px);
padding: 0.375rem 0.75rem;
font-size: 15px;
font-weight: 400;
line-height: 1.5;
color: #495057;
background-color: #ffffff;
background-clip: padding-box;
border: 1px solid #ced4da;
outline: none;
`

const Input = ({
  title = '',

  onChange,
  value,
  secondary,
  id,
  type,
  name,
  h,
  w,
  p,
  m,
  placeholder,
  disabled,
  maxLength,
  onMouseDown,
  onMouseUp,
  onFocus,
  onBlur,
  step,
  min,
  max,
  autoFocus = false,
  isLoading,
  onPaste
}) => {
  return (
    <WrappInput h={h} w={w} p={p} m={m} secondary={secondary}>
      {title}
      <InputStyled
        disabled={disabled}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        name={name}
        maxLength={maxLength}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onFocus={onFocus}
        onBlur={onBlur}
        step={step}
        min={min}
        max={max}
        autoFocus={autoFocus || false}
        onPaste={onPaste}
      />
      {isLoading && (
        <Loader/>
      )}
    </WrappInput>
  )
}

export default Input
