import styled from "styled-components";

export const FlexBlock = styled.div`
  font-family: "Montserrat", sans-serif;
  ${({ pos }) => pos && `position: ${pos}`};
  ${({ posT }) => posT && `top: ${posT}`};
  ${({ posL }) => posL && `left: ${posL}`};
  ${({ posR }) => posR && `right: ${posR}`};
  ${({ posB }) => posB && `bottom: ${posB}`};

  display: ${({ d }) => d || "flex"};
  ${({ flex }) => flex && `flex: ${flex}`};
  ${({ flexW }) => flexW && `flex-wrap: ${flexW}`};

  flex-direction: ${({ fd }) => fd || "row"};
  ${({ jc }) => jc && `justify-content:${jc}`};
  ${({ ai }) => ai && `align-items:${ai}`};

  ${({ p }) => p && `padding: ${p}`};
  ${({ m }) => m && `margin: ${m}`};
  ${({ g }) => g && `gap: ${g}`};

  ${({ w }) => w && `width: ${w}`};
  ${({ minW }) => minW && `min-width: ${minW}`};
  ${({ maxW }) => maxW && `max-width: ${maxW}`};

  ${({ h }) => h && "height: ${h}"};
  ${({ minH }) => minH && `min-height: ${minH}`};
  ${({ maxH }) => maxH && `max-height: ${maxH}`};

  ${({ c }) => c && `color: ${c}`};
  ${({ bg }) => bg && `background: ${bg}`};
  ${({ b }) => b && `border: ${b}`};
  ${({ tr }) => tr && `transition: ${tr}`};
  ${({ fz }) => fz && `font-size: ${fz}`};
`;

export const Row = styled(FlexBlock)``;

export const Col = styled(FlexBlock)`
  flex-direction: ${({ fd }) => fd || "column"};
`;

export const TitleStyled = styled(FlexBlock)`
  color: #7f91a5;
  font-weight: ${({ fw }) => fw || `600`};
  flex-wrap: wrap;
  font-size: 0.875rem;
`;

export const SubtitleStyled = styled(FlexBlock)`
  color: #7f91a5;
  font-weight: ${({ fw }) => fw || `400`};
  flex-wrap: wrap;
  font-size: 0.875rem;
`;
