import React, { useEffect, useRef } from 'react'

const UploadWidget = ({ children, onUpload }) => {
  const cloudinary = useRef(null)
  const widget = useRef(null)

  useEffect(() => {
    (function () {
      if (typeof window !== 'undefined') {
        const script = document.createElement('script')

        script.src = 'https://widget.cloudinary.com/v2.0/global/all.js'
        document.head.append(script)

        script.onload = handleOnLoad
      }
    })()
  }, [])

  const createWidget = () => {
    const options = {
      cloudName: process.env.GATSBY_CLOUD_NAME,
      uploadPreset: process.env.GATSBY_UPLOAD_PRESET,
      folder: 'qr-codes',
      sources: ['local'], 
      multiple: false
    }

    return cloudinary.current.createUploadWidget(
      options,
      (error, result) => {
        if (error || result.event === 'success') {
          onUpload(error, result)
        }
      }
    )
  }

  const open = () => {
    if (!widget.current) {
      widget.current = createWidget()
    }

    widget.current && widget.current.open()
  }

  const handleOnLoad = () => {
    cloudinary.current = window.cloudinary
  }

  return <div>{children({ open })}</div>
}

export default UploadWidget
