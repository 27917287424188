import React, { useCallback } from 'react'
import styled from 'styled-components'

const Li = styled.li`
  text-align: center;
  width: 100%;
  background: #00234b;
  color: white;

  border-bottom: ${({ isActive }) =>
    isActive ? '4px solid #b4975a' : '4px solid #00234b'};

  font-family: "Montserrat", sans-serif;
  font-weight: ${({ isActive }) => isActive && '700'};

  padding: 15px 20px;

  transition: 0.2s;

  cursor: pointer;

  :hover {
    background: ${({ disabled, secondary }) =>
      disabled && secondary
        ? '#5a6268'
        : disabled
        ? '#00234b'
        : secondary
        ? '#5a6268'
        : '#5a6268'};
  }
`

const TabTitle = ({ title, setSelectedTab, index, isActive, onClick }) => {
  const handleOnClick = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index])

  return (
    <Li
      isActive={isActive}
      onClick={() => {
        handleOnClick()
        onClick()
      }}
    >
      {title}
    </Li>
  )
}

export default TabTitle
