import { onGenerateQrCodeGTM } from './googleTagManager'

export const transformSymbols = (str) => {
  return str
    .replaceAll(/[\u2018\u2019]/g, "'")
    .replaceAll(/[\u201C\u201D]/g, '"')
}

export const onCopy = (canvasEl, isTypes, options) => {
  if (!canvasEl) return

  canvasEl.toBlob((blob) => {
    if (!blob) return

    navigator.clipboard.write([
      new window.ClipboardItem({
        [blob.type]: new Promise(async (resolve) => {
          resolve(new Blob([blob], { type: blob.type }))
        })
      })
    ])
  })

  onGenerateQrCodeGTM({
    qr_capture_type: 'copy',
    qr_action: isTypes,
    options
  })
}

export const updateLinkToQr = (ref, linkFromBlob, setLinkFromBlob, size) => {
  if (!ref || !ref.current || !ref.current.children[0] || size.width > 1280) {
    return
  }

  ref.current.children[0].toBlob((blob) => {
    if (!blob || linkFromBlob === URL.createObjectURL(blob)) return

    setLinkFromBlob(URL.createObjectURL(blob))
  })
}

export const newQrWithBigRes = (options) => {
  const QRCodeStyling = require('qr-code-styling') // Only do this on the client

  const qrCode = new QRCodeStyling({
    ...options,
    width: 1024,
    height: 1024,
    margin: 40
  })

  qrCode.applyExtension(extension)

  return qrCode
}

export const extension = (svg, options) => {
  const svgNamespace = 'http://www.w3.org/2000/svg'

  const textElement = document.createElementNS(svgNamespace, 'text')
  textElement.setAttribute('x', options.width >= 1000 ? '40px' : '25px')
  textElement.setAttribute('y', '99%')

  textElement.setAttribute('text-anchor', 'start')
  textElement.setAttribute('font-family', 'sans-serif')
  textElement.setAttribute(
    'font-size',
    options.width >= 1000 ? '32px' : '14px'
  )
  textElement.setAttribute(
    'stroke',
        `${options.dotsOptions.color || '#000'}`
  )
  textElement.setAttribute('fill', `${options.dotsOptions.color || '#000'}`)
  textElement.textContent = transformSymbols(options.textTips)

  svg.appendChild(textElement)
}

export const transformQr = (qrCodeCopy, refQrStyledCopy, options, textTips) => {
  if (!refQrStyledCopy.current) return

  qrCodeCopy.update({ ...options, width: 1024, height: 1024, margin: 40 })

  qrCodeCopy.applyExtension((svg, options) =>
    extension(svg, options, textTips)
  )

  qrCodeCopy.append(refQrStyledCopy.current)
}

export const onDownloadClick = (textTips, isTypes, options) => {
  const qrCode = newQrWithBigRes(options)

  qrCode.download({
    name: textTips || 'PGA-qr-code',
    extension: 'png'
  })

  onGenerateQrCodeGTM({
    qr_capture_type: 'download',
    qr_action: isTypes,
    options
  })
}
