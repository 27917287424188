import React from 'react'

import styled from 'styled-components'
import { Col, FlexBlock, Row } from '../../../../styles/styles'

export const PGA_LOGO_BLUE = "https://res.cloudinary.com/pgahq/image/upload/v1695259568/PGAA-blue_2x.png"
export const PGA_LOGO_WHITE_BLUE_CIRCLE = "https://res.cloudinary.com/pgahq/image/upload/v1695257901/PGAA-TM-blue-circle_2x.png"

const ImageEx = styled.img`
  width: 70px;
  height: 70px;
  object-fit: contain;

  flex: 1 1 auto;
  min-width: 70px;

  opacity: ${({ disabled }) => (disabled ? 0.1 : 1)};
  transition: 0.5s;
`
const exampleImages = [
  {
    title: 'Example №1',
    link: PGA_LOGO_BLUE
  },
  {
    title: 'Example №2',
    link: PGA_LOGO_WHITE_BLUE_CIRCLE
  },
]

const ExampleImages = ({
  options,
  setOptions,
  setWithoutImage,
  withoutImage,
  setShowWithoutImage
}) => {
  return (
    <Col g='5px' fz='15px'>
      Examples:
      <Row g='5px' ai='center'>
        {exampleImages.map((example, i) => {
          return (
            <FlexBlock
              key={example.title + '_' + i}
              b={
                example.link === options.image
                  ? '2px solid #06234B'
                  : '1px solid #06234B'
              }
              flex='1 1 auto'
              tr='0.2s'
              fz='15px'
            >
              <ImageEx
                disabled={withoutImage}
                src={example.link}
                alt={`Example image ${i}`}
                onClick={() => {
                  setShowWithoutImage(true)
                  setWithoutImage(false)

                  setOptions({
                    ...options,
                    image: example.link
                  })
                }}
              />
            </FlexBlock>
          )
        })}
      </Row>
    </Col>
  )
}

export default ExampleImages
