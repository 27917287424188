import React, { useState } from 'react'
import styled from 'styled-components'

import TabTitle from './TabTitle'
import { Col } from '../../../../styles/styles'

const TabsStyled = styled(Col)`
  ul {
    display: flex;
    flex-direction: row;
    list-style: none;

    background: #00234b;

    padding: 0;
  }
`

const Tabs = ({
  children,
  preSelectedTabIndex,
  setOptions,
  options,
  setIsTypes,
  isTypes
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    preSelectedTabIndex || 0
  )

  return (
    <TabsStyled>
      <ul>
        {children.map((item, index) => (
          <TabTitle
            key={item.props.title}
            title={item.props.title}
            onClick={() => {
              setOptions({
                ...options,
                data: isTypes.includes('url') ? 'https://' : ' '
              })

              setIsTypes(item.props.title.toLowerCase()) // need for GTM
            }}
            index={index}
            isActive={index === selectedTabIndex}
            setSelectedTab={setSelectedTabIndex}
          />
        ))}
      </ul>

      {children[selectedTabIndex]}
    </TabsStyled>
  )
}

export default Tabs
