import React from 'react'
import { Col } from '../../../styles/styles'
import Customizations from './Customizations'
import Tips from './QrTips'
import UrlType from './QrDataTypes/UrlType'
import WiFiType from './QrDataTypes/WiFiType'
import Tabs from '../reusable/Tabs/Tabs'
import SmsType from './QrDataTypes/SmsType'

const regexQrValues = /^(https:\/\/|smsto:|WIFI:T:WPA;S:;P:;H:false;;| )$/gm

export default function Settings ({
  options,
  setOptions,
  qrCode,
  textTips,
  setTextTips,
  setIsTypes,
  isTypes,
  size
}) {
  const arr = [
    {
      label: 'OPEN WEBPAGE',
      title: 'URL',
      component: <UrlType options={options} setOptions={setOptions} />
    },
    {
      label: 'JOIN WiFi',
      title: 'WiFi',
      component: <WiFiType options={options} setOptions={setOptions} size={size} />
    },
    {
      label: 'SEND A TEXT',
      title: 'SMS',
      component: <SmsType options={options} setOptions={setOptions} size={size} />
    }
  ]

  return (
    <Col g='20px' flex='1 1 50%'>
      <Tabs
        setIsTypes={setIsTypes}
        isTypes={isTypes}
        setOptions={setOptions}
        options={options}
      >
        {arr.map((el, i) => {
          return (
            <div key={i} title={el.label}>
              {el.component}
            </div>
          )
        })}
      </Tabs>

      {!options.data.match(regexQrValues) && (
        <>
          <Customizations options={options} setOptions={setOptions} />
          <Tips
            qrCode={qrCode}
            textTips={textTips}
            setTextTips={setTextTips}
            setOptions={setOptions}
            options={options}
          />
        </>
      )}
    </Col>
  )
}
