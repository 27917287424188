import React, { useEffect, useMemo, useState } from 'react'
import Input from '../../reusable/Input'
import { Col } from '../../../../styles/styles'
import RedStar from '../../reusable/RedStar'
import DescriptionForTabs from '../../reusable/Tabs/DescriptionForTabs'
import { transformSymbols } from '../../../../utils/qrUtils'
import useDebounce from '../../../../hooks/useDebounce'

const dataDescription = {
  subtitle: 'Quickly send a text message:',
  description:
        "When someone scans the QR code from their phone, it'll pre-fill a text message to the phone number you specify"
}

const SmsType = ({ options, setOptions, size }) => {
  const [sms, setSms] = useState({
    tel: '',
    text: ''
  })
  
  const debounceValue = useDebounce(sms, 1100)
  const triggerSms = size.width < 1024 ? debounceValue : sms

  useEffect(() => {
    let formatedSmsCode = `smsto:${sms.tel.trim()}`

    if (sms.text.length > 0) { formatedSmsCode += `:${transformSymbols(sms.text)}` }

    setOptions({ ...options, data: formatedSmsCode })
  }, [triggerSms])

  return (
    <Col g='20px'>
      <DescriptionForTabs data={dataDescription} />

      <Col>
        <label htmlFor='tel'>
          Your phone number
          <RedStar />:
        </label>
        <Input
          id='tel'
          value={sms.tel}
          onChange={(e) => setSms({ ...sms, tel: e.target.value })}
          placeholder='+18005551212'
          type='number'
          autoFocus
        />
      </Col>

      <Col>
        <label htmlFor='text'>Custom message:</label>
        <Input
          id='text'
          value={sms.text}
          onChange={(e) => setSms({ ...sms, text: e.target.value })}
          placeholder='Your SMS text'
          maxLength={159}
        />
      </Col>
    </Col>
  )
}

export default SmsType
