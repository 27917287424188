import React from 'react'
import styled from 'styled-components'
import { Col } from '../../../styles/styles'
import { PGA_LOGO_BLUE } from '../Settings/EditQrImage/ExampleImages'

const BoxStyled = styled(Col)`
    display: ${({ isShow }) => (isShow ? 'flex' : 'none')};

    align-items: center;
    justify-content: center;
    margin: 0px auto;

    border: 1px solid black;
    border-radius: 35px;

    margin-top: 15px;
    padding: 5px;
    text-align: center;

    aspect-ratio: 1 / 1;

    width: ${({ XL }) => (XL < 800 ? `${XL}px` : '100%')};
    height: ${({ XL }) => (XL < 800 ? `${XL}px` : '100%')};
`

const ImageEx = styled.img`
    width: 60%;

    object-fit: contain;
    aspect-ratio: 1 / 1;
`

export const QrPlaceholder = ({ isShow, size }) => {
  return (
    <BoxStyled isShow={isShow} XL={size - size * 0.1}>
      <ImageEx src={PGA_LOGO_BLUE} alt='Example logo' />
    </BoxStyled>
  )
}
