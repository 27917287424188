import React from 'react'

import EditQrImage from './EditQrImage/EditQrImage'
import Accordion from '../reusable/Accordeon'
import { Col } from '../../../styles/styles'

export default function Customizations ({ options, setOptions }) {
  return (
    <Accordion
      title='Add a Logo'
      content={
        <Col g='5px'>
          <EditQrImage options={options} setOptions={setOptions} />
        </Col>
      }
    />
  )
}
