import React, { useState } from 'react'
import { onCopy } from '../../../utils/qrUtils'
import Button from '../reusable/Button'

export const Copy = ({
  refQrStyledCopy,
  options,
  isTypes,
  size,
  disabled = false
}) => {
  const [isCopied, setIsCopied] = useState(false)

  return (
    <Button
      onClick={() => {
        setIsCopied(true)

        const canvasEl = refQrStyledCopy.current.children[0]
        onCopy(canvasEl, isTypes, options)

        setTimeout(() => {
          setIsCopied(false)
        }, 2500)
      }}
      disabled={!!(disabled || isCopied)}
      title={!isCopied ? 'Copy' : 'Copied!'}
      w={size.width < 1280 ? '100%' : '50%'}
      m='0 auto'
    />
  )
}
