import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import 'bootstrap/dist/css/bootstrap.css'

import Settings from './Settings'
import QrMain from './QrMain'
import { FlexBlock } from '../../styles/styles'
import { roundToTheNearestTen } from '../../utils/roundingNumbers'
import useWindowSize from '../../hooks/useWindowSize'

const WrappElement = styled(FlexBlock)`
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin: 20px 0 100px 0;
  width: 100%;

  @media (max-width: 1280px) {
    flex-direction: column;
  }
`

const Qr = ({ qrCode, options, setOptions }) => {
  const size = useWindowSize()
  const [textTips, setTextTips] = useState('')
  const [isTypes, setIsTypes] = useState('url')

  useEffect(() => {
    const XL =
      size.width >= 1280
        ? roundToTheNearestTen(size.width, 4) // count - "4" times smaller than the screen
        : 1024

    const margin = size.width >= 1280 ? 15 : 35

    setOptions({
      ...options,
      width: XL,
      height: XL,
      margin
    })
  }, [size])

  useEffect(() => {
    if (!qrCode) return

    qrCode.update(options)
  }, [qrCode, options])

  return (
    <WrappElement>
      <Settings
        textTips={textTips}
        options={options}
        setOptions={setOptions}
        setIsTypes={setIsTypes}
        isTypes={isTypes}
        qrCode={qrCode}
        setTextTips={setTextTips}
        size={size}
      />

      <QrMain
        size={size}
        textTips={textTips}
        isTypes={isTypes}
        options={options}
        qrCode={qrCode}
      />
    </WrappElement>
  )
}

export default Qr
