import { useEffect, useState } from 'react'

const useDebounce = (value, delay, setIsLoading) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    if(setIsLoading) setIsLoading(true)

    const timer = setTimeout(() => {
      setDebouncedValue(value)

      if(setIsLoading) setIsLoading(false)
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
